export const popularBanksIndia = [
  "State Bank of India",
  "HDFC Bank",
  "ICICI Bank",
  "Axis Bank",
  "Kotak Mahindra Bank",
  "Punjab National Bank",
  "Bank of Baroda",
  "Canara Bank",
  "IndusInd Bank",
  "Union Bank of India",
  "Bank of India",
  "IDFC First Bank",
  "Central Bank of India",
  "Indian Bank",
  "Yes Bank",
];
export const popularBanksUS = [
  "JPMorgan Chase",
  "Bank of America",
  "Wells Fargo",
  "Citibank",
  "U.S. Bank",
  "PNC Bank",
  "Truist Bank",
  "Goldman Sachs",
  "TD Bank",
  "Capital One",
  "HSBC USA",
  "Charles Schwab",
  "Fifth Third Bank",
  "Ally Bank",
  "American Express Bank",
];
